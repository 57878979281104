
import { defineComponent } from "vue";

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint.js";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "asssessment-dashboard",
  mixins: [apiEndpoint],
  components: {
    Datatable,
  },
  data() {
    return {
       trainee: {
        tranche_id: "",
      },
      tranches: [],
      assessmentDashboardData: [],
      assessmentDashboardCount: {},
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Logo",
          key: "logo",
          sortable: true,
        },
        {
          name: "Training Partner",
          key: "training_partner",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Batch",
          key: "batch",
          sortable: true,
        },
        {
          name: "Trainee Assessed",
          key: "trainee_assessed",
          sortable: true,
        },

        {
          name: "Trainee Dropout",
          key: "trainee_dropout",
          sortable: true,
        },
        {
          name: "Trainee Absent",
          key: "trainee_absent",
          sortable: true,
        },
        {
          name: "Female Assessed",
          key: "female_assessed",
          sortable: true,
        },
        {
          name: "Assessor",
          key: "assessor",
          sortable: true,
        },
        {
          name: "Dues over 30 days",
          key: "dues",
          sortable: true,
        },
      ],
      api_url: '',
      load: false,
      loading: false,
      courseInfoData: false,
      showDashboardData: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranches();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async getTranches() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessmentDashboardCount() {
      this.load = true;
      let entity_id = "";
      let institute_info_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_info_id = VueCookieNext.getCookie("_institute_info_id");
      }
      await ApiService.get(
        "assessment/dashboard_count?tranche_id=" +
          this.trainee.tranche_id+"&entity_id=" + entity_id+"&training_institute_id="+ institute_info_id

        )
        .then((response) => {
          this.assessmentDashboardCount = response.data.data;
          this.getAssessmentDashboardData();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssessmentDashboardData() {
      this.load = true;
        let entity_id = "";
        let institute_info_id = "";
        if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
          entity_id = VueCookieNext.getCookie("_entity_id");
        }
        if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
          institute_info_id = VueCookieNext.getCookie("_institute_info_id");
        }
      await ApiService.get(
        "assessment/dashboard_data?tranche_id=" +
          this.trainee.tranche_id+"&entity_id=" + entity_id+"&training_institute_id="+ institute_info_id
        )
        .then((response) => {
          this.assessmentDashboardData = response.data.data;
          this.showDashboardData = true;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
  },
});
